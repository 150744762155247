import { mediaMax, primaryColor, rgba } from '@styles/utils';
import styled, { css } from 'styled-components';

export const MENU_WIDTH = 270;

export const NavContainer = styled.nav<{ isOpen: boolean }>`
  display: flex;
  flex: 0 0 ${MENU_WIDTH}px;
  flex-direction: column;
  width: ${MENU_WIDTH}px;
  height: 100%;
  background: linear-gradient(135deg, #8f75da 0%, ${primaryColor} 60%);

  ${props => mediaMax.large`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
    transition: transform 0.2s ease-in-out;
    transform: translateX(-100%);

    
    ${
      props.isOpen &&
      css`
        box-shadow: 4px 1px 11px 1px rgba(0, 0, 0, 0.1);
        transform: translateX(0);
      `
    };
  `};
`;

export const NavBurger = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 20px;
  left: 15px;
  width: 30px;
  height: 30px;
  z-index: 10001;
  display: none;
  opacity: 0.8;
  cursor: pointer;

  ${mediaMax.large`
    display: block;
  `}

  > span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    background: #2a2626;
    transform: translateY(-2px);
    border-radius: 2px;
    transition-property: transform;
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background: #2a2626;
      border-radius: 2px;
    }

    &:before {
      top: -10px;
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    &:after {
      bottom: -10px;
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    ${props =>
      props.isOpen &&
      css`
        transform: rotate(45deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &:before {
          top: 0;
          opacity: 0;
          transition: top 0.075s ease, opacity 0.075s 0.12s ease;
        }

        &:after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      `};
  }
`;

export const NavHeader = styled.div`
  min-height: 70px;
  background: #ffffff;

  ${mediaMax.large`
      border-right: 1px solid #ddd;
  `};

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    ${mediaMax.large`
      margin-left: 60px;
      padding-left: 0;
    `};

    > img {
      width: 50px;
    }
  }
`;

export const NavContent = styled.div`
  flex: 1 1 auto;
  padding: 15px 0;
  overflow-y: auto;
`;

export const NavEntries = styled.ul`
  padding: 5px 15px;
  margin: 0;
  list-style: none;

  li {
    padding-bottom: 25px;

    a {
      display: flex;
      align-items: center;
      padding: 8px 15px;
      color: #fff;
      border-radius: 6px;
      background: transparent;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: ${props => rgba(primaryColor, 0.7)} !important;
      }

      &.active {
        background: ${props => rgba(primaryColor, 0.9)} !important;
        color: ${props => props.theme.colors.white};
      }

      > div {
        display: flex;
        column-gap: 5px;
      }
    }
  }
`;

export const NavFooter = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 0 15px;
  background: url(#fff) no-repeat top left;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.04);

  > a {
    flex: 1 1 auto;
    padding-right: 5px;
    color: #fff;
    font-size: 14px;
    transition: color 0.15s linear;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 0.7;
    font-weight: 600;

    &:hover {
      color: rgba(255, 255, 255, 0.8);
    }

    > p {
      margin: 0;

      &:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;

export const LogoutButton = styled.div`
  width: 25px;
  height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
`;
