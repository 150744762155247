import { FieldMetaProps, FormikValues } from 'formik';

import { ReactNode, SyntheticEvent } from 'react';

import * as O from 'fp-ts/Option';
import * as EI from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';

import { renderNullable } from '@shared/utils/render';
import { HttpResult } from '@core/http';
import { DropdownProps, Label } from 'semantic-ui-react';

export function hasError<T extends Record<string, any> = {}>(
  getFieldMeta: (name: string) => FieldMetaProps<any>,
): (name: Extract<keyof T, string> | string) => boolean {
  return name => {
    const meta = getFieldMeta(name);

    return !!meta.error && meta.touched;
  };
}

export function getErrorMessage<T extends Record<string, any> = {}>(
  getFieldMeta: (name: string) => FieldMetaProps<any>,
): (name: Extract<keyof T, string> | string) => string | undefined {
  return name => {
    const meta = getFieldMeta(name);

    return pipe(
      O.fromNullable(meta.error),
      O.filter(() => meta.touched),
      O.toUndefined,
    );
  };
}

export function renderErrorMessage<T extends Record<string, any> = {}>(
  getFieldMeta: (name: string) => FieldMetaProps<any>,
): (name: Extract<keyof T, string> | string, renderer: (error: string) => ReactNode) => ReactNode {
  return (name, renderer) => renderNullable(getErrorMessage(getFieldMeta)(name), renderer);
}

export function renderDefaultErrorMessage<T extends Record<string, any> = {}>(
  getFieldMeta: (name: string) => FieldMetaProps<any>,
): (name: Extract<keyof T, string> | string) => ReactNode {
  return name =>
    renderErrorMessage(getFieldMeta)(name, error => (
      <Label basic color="red" pointing>
        {error}
      </Label>
    ));
}

export function mapToPreventLeaveResult<T>(
  res: HttpResult<T>,
  fn?: (right: T) => string,
): EI.Either<unknown, O.Option<string>> {
  return pipe(
    res,
    EI.map(right => (fn ? O.some(fn(right)) : O.none)),
  );
}

export function formikDropdownAdapter<Values = FormikValues>(
  setFieldValue: (field: keyof Values & string, value: any, shouldValidate?: boolean) => void,
): (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => void {
  return (_, { name, id, value }) => {
    const field = pipe(
      O.fromNullable(name),
      O.alt(() => O.fromNullable(id)),
      O.toNullable,
    );

    if (field) {
      setFieldValue(field, value);
    }
  };
}
