import { Routes, safeLazy } from '@core/router';
import { Profile } from '@modules/auth/model';
import ProfileUpdatePage from '@shared/modules/profile/UpdatePage';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';

const ArticlesRoutes = safeLazy(() => import('@shared/modules/articles/routes'));
const UsersRoutes = safeLazy(() => import('@modules/admin/users/routes'));
const ParametersRoutes = safeLazy(() => import('@modules/admin/parameters/routes'));
const RubricsRoutes = safeLazy(() => import('@modules/admin/rubrics/routes'));

interface AdminRoutesProps {
  profile: Profile;
}

const AdminRoutes: FC<AdminRoutesProps> = ({ profile }) => (
  <Routes>
    <Route path="/articles" component={ArticlesRoutes} />
    <Route path="/users" component={UsersRoutes} />
    <Route path="/parameters" component={ParametersRoutes} />
    <Route path="/rubrics" component={RubricsRoutes} />
    <Route path="/profile" component={ProfileUpdatePage} />
  </Routes>
);

export default AdminRoutes;
