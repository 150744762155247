import { UserBODetailParam } from '@modules/admin/users/model';
import { Profile, ProfileParams } from '@modules/auth/model';
import { hasError } from '@shared/modules/form';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { Form, Input, Segment } from 'semantic-ui-react';

interface ProfileFormProps {
  profile: Profile;
}

const ProfileForm: FC<ProfileFormProps> = ({ profile }) => {
  const { values, handleChange, handleSubmit, getFieldMeta } = useFormikContext<ProfileParams>();

  const errorGetter = hasError<UserBODetailParam>(getFieldMeta);

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Segment padded>
        <Form.Group widths="equal">
          <Form.Field required error={errorGetter('lastName')}>
            <label htmlFor="lastName">Nom</label>
            <Input id="lastName" name="lastName" value={values.lastName} placeholder="Nom" onChange={handleChange} />
          </Form.Field>
          <Form.Field required error={errorGetter('firstName')}>
            <label htmlFor="firstName">Prénom</label>
            <Input
              id="firstName"
              name="firstName"
              value={values.firstName}
              placeholder="Prénom"
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field disabled={true} required error={errorGetter('email')}>
            <label htmlFor="email">E-mail</label>
            <Input id="email" name="email" value={profile.email} placeholder="E-mail" />
          </Form.Field>
        </Form.Group>
      </Segment>
    </Form>
  );
};

export default ProfileForm;
