import { Newtype } from 'newtype-ts';

export type UserId = Newtype<{ readonly ID: unique symbol }, string> & string;

export enum UserRole {
  Admin = 'admin',
  Writer = 'writer',
}

export const userRoleLabel: Record<UserRole, string> = {
  [UserRole.Admin]: 'Administrateur',
  [UserRole.Writer]: 'Rédacteur',
};

export interface CreateUserParams {
  email: string;
  firstName: string;
  lastName: string;
}

export interface UpdateUserParams {
  firstName: string;
  lastName: string;
  phone?: string | null;
  address?: UserAddress;
}

export interface UserAddress {
  address?: string;
  postalCode?: string;
  city?: string;
}

export interface UpdateUserEmailParams {
  email: string;
}

export interface UpdateUserEmailFormBody {
  email: string;
  confirmEmail: string;
}

export interface UpdateUserPasswordParams {
  oldPassword: string;
  newPassword: string;
}

export interface UpdateUserPasswordFormBody {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}
