import { BackgroundColor, Colors, DefaultTheme, PrimaryColor, SecondaryColor, TertiaryColor } from 'styled-components';

const primary: PrimaryColor = '#727cf5';

const secondary: SecondaryColor = '#3d7877';

const tertiary: TertiaryColor = '#6c757d';

const background: BackgroundColor = '#f7f7f7';

const colors: Colors = {
  primary,
  secondary,
  tertiary,
  background,
  black: tertiary[900],
  white: '#FFFFFF',
  dark: tertiary[800],
  error: '#ef4e58',
};

const theme: DefaultTheme = {
  colors,
  breakpoints: {
    large: 1280,
    desktop: 960,
    tablet: 760,
    mobile: 560,
  },
};

export default theme;
