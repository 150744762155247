import { Routes, safeLazy } from '@core/router';
import { Profile } from '@modules/auth/model';
import ProfileUpdatePage from '@shared/modules/profile/UpdatePage';
import React, { FC } from 'react';
import { Route } from 'react-router-dom';

const ArticlesRoutes = safeLazy(() => import('@shared/modules/articles/routes'));

interface WriterRoutesProps {
  profile: Profile;
}

const WriterRoutes: FC<WriterRoutesProps> = ({ profile }) => (
  <Routes>
    <Route path="/articles" component={ArticlesRoutes} />
    <Route path="/Profile" component={ProfileUpdatePage} />
  </Routes>
);

export default WriterRoutes;
