import { Routes, safeLazy } from '@core/router';
import Layout from '@layout/Layout';
import AdminRoutes from '@modules/admin/routes';
import { useAuthContext } from '@modules/auth/context';
import { Profile } from '@modules/auth/model';
import WriterRoutes from '@modules/writer/routes';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { UserRole } from '@shared/modules/users/model';
import { renderOptional } from '@shared/utils/render';
import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

const AuthRoutes = safeLazy(() => import('@modules/auth/routes'));

interface PrivateRoutesProps {
  profile: Profile;
}

const PrivateRoutesContent: FC<PrivateRoutesProps> = ({ profile }) => {
  switch (profile.role) {
    case UserRole.Admin:
      return <AdminRoutes profile={profile} />;
    case UserRole.Writer:
      return <WriterRoutes profile={profile} />;
  }
};

const PrivateRoutes: FC<PrivateRoutesProps> = ({ profile }) => (
  <Layout profile={profile}>
    <PrivateRoutesContent profile={profile} />
  </Layout>
);

const RootRoutes: FC = () => {
  const { profile } = useAuthContext();
  return (
    <SentryProvider profile={profile}>
      <Routes>
        <Redirect exact from="/" to="/articles" />
        <Route path={['/login', '/password-reset', '/activation']} component={AuthRoutes} />;
        {renderOptional(
          profile,
          profile => (
            <Route path="/">
              <PrivateRoutes profile={profile} />
            </Route>
          ),
          () => (
            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: window.location.pathname },
              }}
            />
          ),
        )}
      </Routes>
    </SentryProvider>
  );
};

export default RootRoutes;
