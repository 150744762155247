import { httpService, HttpTask } from '@core/http';
import {
  ActivationDetail,
  ActivationParams,
  ActivationToken,
  AuthenticateParams,
  ForgotPasswordParams,
  Profile,
  ProfileParams,
  ResetPasswordParams,
  ResetPasswordToken,
} from '@modules/auth/model';
import { UpdateUserEmailParams, UpdateUserPasswordParams } from '@shared/modules/users/model';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';

export function getProfile(): HttpTask<Profile> {
  return httpService.get('/profile');
}

export function authenticate(params: AuthenticateParams): HttpTask<Profile> {
  return pipe(httpService.post('/authenticate', params), TE.chain(getProfile));
}

export function logout(): HttpTask {
  return httpService.post('/logout');
}

export function forgotPassword(params: ForgotPasswordParams): HttpTask {
  return httpService.post('/password-reset/forgot', params);
}

export function getPasswordResetDetail(token: ResetPasswordToken): HttpTask<ForgotPasswordParams> {
  return httpService.get(`/password-reset/${token}`);
}

export function resetPassword(token: ResetPasswordToken, params: ResetPasswordParams): HttpTask {
  return httpService.post(`/password-reset/${token}`, params);
}

export function getActivationDetail(token: ActivationToken): HttpTask<ActivationDetail> {
  return httpService.get(`/activation/${token}`);
}

export function activateAccount(token: ActivationToken, params: ActivationParams): HttpTask<Profile> {
  return pipe(httpService.post(`/activation/${token}`, params), TE.chain(getProfile));
}

export function updateProfile(params: ProfileParams): HttpTask {
  return httpService.post('/profile', params);
}

export function updateProfileEmail(params: UpdateUserEmailParams): HttpTask {
  return httpService.post('/profile/change-email', params);
}

export function updateProfilePassword(params: UpdateUserPasswordParams): HttpTask {
  return httpService.post('/profile/change-password', params);
}
