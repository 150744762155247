import logo from '@assets/logos/logo.png';
import { useAuthContext } from '@modules/auth/context';
import { Profile } from '@modules/auth/model';
import { UserRole, userRoleLabel } from '@shared/modules/users/model';
import { sequenceT } from 'fp-ts/Apply';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import * as Styled from './Nav.styles';

interface NavEntry {
  title: string;
  to: string;
  icon?: SemanticICONS;
}

function getNullableName(firstName: string | null, lastName: string | null, fallback: string): string {
  return pipe(
    sequenceT(O.Apply)(O.fromNullable(firstName), O.fromNullable(lastName)),
    O.fold(
      () => fallback,
      ([firstName, lastName]) => `${firstName} ${lastName}`,
    ),
  );
}

function getProfileName(profile: Profile): string {
  switch (profile.role) {
    case UserRole.Admin:
      return getNullableName(profile.firstName, profile.lastName, 'Admin');
    case UserRole.Writer:
      return getNullableName(profile.firstName, profile.lastName, 'Rédacteur');
  }
}

function getNavEntries(profile: Profile): Array<NavEntry> {
  switch (profile.role) {
    case UserRole.Admin:
      return [
        { title: 'Rubriques', to: '/rubrics', icon: 'briefcase' },
        { title: 'Articles', to: '/articles', icon: 'file alternate' },
        { title: 'Utilisateurs', to: '/users', icon: 'user' },
        { title: 'Paramètres', to: '/parameters', icon: 'cog' },
      ];
    case UserRole.Writer:
      return [{ title: 'Articles', to: '/articles' }];
    default:
      return [];
  }
}

interface NavProps {
  profile: Profile;
}

const Nav: FC<NavProps> = ({ profile }) => {
  const location = useLocation();

  const { logout } = useAuthContext();

  const navRef = useRef<HTMLDivElement>(null);
  const burgerRef = useRef<HTMLDivElement>(null);

  const [navOpen, setNavOpen] = useState<boolean>(false);

  const toggleNav = () => setNavOpen(old => !old);

  const entries = useMemo(() => getNavEntries(profile), [profile]);

  useEffect(() => {
    setNavOpen(false);
  }, [location.pathname]);

  return (
    <>
      <Styled.NavBurger ref={burgerRef} isOpen={navOpen} onClick={toggleNav}>
        <span />
      </Styled.NavBurger>
      <Styled.NavContainer ref={navRef} isOpen={navOpen}>
        <Styled.NavHeader>
          <Link to="/articles">
            <img src={logo} alt="logo" width="50px" />
          </Link>
        </Styled.NavHeader>
        <Styled.NavContent>
          <Styled.NavEntries>
            {entries.map((entry, j) => (
              <li key={j}>
                <NavLink to={entry.to} activeClassName="active">
                  <div>
                    {entry.icon && <Icon name={entry.icon} />}
                    <span>{entry.title}</span>
                  </div>
                </NavLink>
              </li>
            ))}
          </Styled.NavEntries>
        </Styled.NavContent>
        <Styled.NavFooter>
          <Link to={`/profile`}>
            <p>{getProfileName(profile)}</p>
            <p>{userRoleLabel[profile.role]}</p>
          </Link>
          <Styled.LogoutButton onClick={logout}>
            <Icon name="sign-out" />
          </Styled.LogoutButton>
        </Styled.NavFooter>
      </Styled.NavContainer>
    </>
  );
};

export default Nav;
