import { rgba as polishedRgba } from 'polished';
import { DefaultTheme, ThemeProps } from 'styled-components';

// function getColor<C extends Record<string, string>>(
//   color: C,
//   defaultKey: Extract<keyof C, number>,
//   key?: Extract<keyof C, number>,
// ): string {
//   return color[key ?? defaultKey];
// }

// export function primaryColor(key?: Extract<keyof PrimaryColor, number>): (props: ThemeProps<DefaultTheme>) => string {
//   return ({ theme }) => getColor(theme.colors.primary, 500, key);
// }

// export function secondaryColor(
//   key?: Extract<keyof SecondaryColor, number>,
// ): (props: ThemeProps<DefaultTheme>) => string {
//   return ({ theme }) => getColor(theme.colors.secondary, 500, key);
// }

// export function tertiaryColor(key?: Extract<keyof TertiaryColor, number>): (props: ThemeProps<DefaultTheme>) => string {
//   return ({ theme }) => getColor(theme.colors.tertiary, 700, key);
// }

// export function backgroundColor(
//   key?: Extract<keyof BackgroundColor, number>,
// ): (props: ThemeProps<DefaultTheme>) => string {
//   return ({ theme }) => getColor(theme.colors.background, 100, key);
// }

export function primaryColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.primary;
}

export function secondaryColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.secondary;
}

export function tertiaryColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.tertiary;
}

export function backgroundColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.background;
}

export function blackColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.black;
}

export function whiteColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.white;
}

export function darkColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.dark;
}

export function errorColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.error;
}

export function rgba(
  colorFn: (props: ThemeProps<DefaultTheme>) => string,
  amount: number,
): (props: ThemeProps<DefaultTheme>) => string {
  return props => polishedRgba(colorFn(props), amount);
}
