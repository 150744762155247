import { useCallback, useState } from 'react';
import { PageProps } from './Page';

export function usePageProps(props?: PageProps) {
  const [pageProps, setPageProps] = useState<PageProps>(() => props ?? {});

  const updatePageProps = useCallback((props: PageProps) => setPageProps(old => ({ ...old, ...props })), []);

  return {
    pageProps,
    updatePageProps,
  };
}
