import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import Seo from '@shared/modules/seo/Seo';
import { renderOptional } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Breadcrumb as SemanticBreadcrumb, BreadcrumbSectionProps, SemanticShorthandItem } from 'semantic-ui-react';
import * as Styled from './Page.styles';

export const PAGE_SCROLLER_ID = 'page-scroller';

export interface Tab {
  title: string;
  to: string;
  exact?: boolean;
}

export interface Breadcrumb {
  title: ReactNode;
  displayTitle?: string;
  to?: string;
}

export interface BottomBarProps {
  left?: ReactNode;
  right?: ReactNode;
}

export interface PageProps {
  breadcrumbs?: Breadcrumb | Array<Breadcrumb>;
  tabs?: Array<Tab>;
  bottom?: BottomBarProps;
  loading?: boolean;
}

const PageTabs: FC<{ tabs?: Array<Tab> }> = ({ tabs }) => {
  if (tabs && tabs.length) {
    const panes = tabs.map(tab => ({
      menuItem: {
        key: tab.to,
        as: NavLink,
        to: tab.to,
        exact: tab.exact,
        strict: true,
        content: tab.title,
        activeClassName: 'active',
        active: false,
      },
    }));

    const menu = { secondary: true, pointing: true };

    return <Styled.PageTabs menu={menu} panes={panes} />;
  }

  return null;
};

const PageBottomBar: FC<{ bottom?: BottomBarProps }> = ({ bottom }) => {
  const optBottom = O.fromNullable(bottom);

  const leftContent = pipe(
    optBottom,
    O.chainNullableK(b => b.left),
  );

  const rightContent = pipe(
    optBottom,
    O.chainNullableK(b => b.right),
  );

  if (O.isSome(leftContent) || O.isSome(rightContent)) {
    return (
      <Styled.PageBottomBar>
        <div>
          <Styled.PageBottomBarLeft>{O.toNullable(leftContent)}</Styled.PageBottomBarLeft>
          <Styled.PageBottomBarRight>{O.toNullable(rightContent)}</Styled.PageBottomBarRight>
        </div>
      </Styled.PageBottomBar>
    );
  }

  return null;
};

const Page: FC<PropsWithChildren<PageProps>> = ({ breadcrumbs, tabs, bottom, loading, children }) => {
  const isLoading = loading || children == null;

  const breadcrumbsOpt = pipe(
    O.fromNullable(breadcrumbs),
    O.map(breadcrumbs => (Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs])),
  );

  const seoTitle: string | undefined = pipe(
    breadcrumbsOpt,
    O.map(breadcrumbs => breadcrumbs.map(b => b.displayTitle ?? b.title).join(' - ')),
    O.toUndefined,
  );

  const breadcrumbSections: O.Option<Array<SemanticShorthandItem<BreadcrumbSectionProps>>> = pipe(
    breadcrumbsOpt,
    O.map(breadcrumbs =>
      breadcrumbs.map<SemanticShorthandItem<BreadcrumbSectionProps>>((b, i) => ({
        key: i,
        content: b.title,
        as: b.to ? Link : undefined,
        to: b.to,
        active: i === breadcrumbs.length - 1,
      })),
    ),
  );

  return (
    <Styled.PageContainer>
      <Seo title={seoTitle} />

      {renderOptional(breadcrumbSections, bs => (
        <Styled.PageTopBar>
          <Styled.PageTopBarLeft>
            <SemanticBreadcrumb icon="chevron right" sections={bs} />
          </Styled.PageTopBarLeft>
        </Styled.PageTopBar>
      ))}

      {isLoading && <DebouncedLineLoader />}

      <PageTabs tabs={tabs} />

      <Styled.PageContentWrapper id={PAGE_SCROLLER_ID}>
        <Styled.PageContent>
          <Styled.PageChildren>{children}</Styled.PageChildren>
        </Styled.PageContent>
      </Styled.PageContentWrapper>

      <PageBottomBar bottom={bottom} />
    </Styled.PageContainer>
  );
};

export default Page;
