import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

const TITLE_PREFIX = 'Sens';

interface SeoProps {
  title?: string;
}

const Seo: FC<SeoProps> = ({ title }) => {
  const metaTitle = pipe(
    O.fromNullable(title),
    O.fold(
      () => TITLE_PREFIX,
      t => `${TITLE_PREFIX} - ${t}`,
    ),
  );

  return (
    <Helmet>
      <title>{metaTitle}</title>
    </Helmet>
  );
};

export default Seo;
