import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button as SemanticButton, ButtonProps as SemanticButtonProps } from 'semantic-ui-react';

export type ButtonType =
  | 'create'
  | 'save'
  | 'cancel'
  | 'delete'
  | 'denied'
  | 'validate'
  | 'modify'
  | 'publish'
  | 'remove';

const buttonPropsByType: Record<ButtonType, SemanticButtonProps> = {
  create: {
    primary: true,
    content: 'Créer',
    labelPosition: 'left',
    icon: 'add',
  },
  save: {
    secondary: true,
    content: 'Enregistrer',
    labelPosition: 'left',
    icon: 'save',
  },
  modify: {
    secondary: true,
    content: 'Modifier',
    labelPosition: 'left',
    icon: 'write',
  },
  cancel: {
    content: 'Annuler',
  },
  delete: {
    color: 'red',
    content: 'Supprimer',
    labelPosition: 'left',
    icon: 'trash alternate outline',
  },
  remove: {
    content: 'Retirer',
    labelPosition: 'left',
    icon: 'times',
  },
  denied: {
    color: 'red',
    content: 'Refuser',
    labelPosition: 'left',
    icon: 'close',
  },
  validate: {
    primary: true,
    content: 'Valider',
    labelPosition: 'left',
    icon: 'check',
  },
  publish: {
    secondary: true,
    content: 'Publier',
    labelPosition: 'left',
    icon: 'paper plane',
  },
};

export interface ButtonProps extends SemanticButtonProps {
  btnType?: ButtonType;
  show?: boolean;
}

export const SharedButton: FC<ButtonProps> = ({ btnType, show, disabled, loading, className, ...buttonProps }) => {
  if (show === false) {
    return null;
  }

  const buttonTypeProps = btnType ? buttonPropsByType[btnType] : {};

  const buttonDisabled = loading || disabled;

  return (
    <SemanticButton
      className={`${className ?? ''} ${btnType ?? ''}`}
      {...buttonTypeProps}
      {...buttonProps}
      disabled={buttonDisabled}
      loading={loading}
    />
  );
};

export interface SharedButtonLinkProps extends ButtonProps {
  to: string;
}

export const SharedButtonLink: FC<SharedButtonLinkProps> = ({ to, btnType, ...buttonProps }) => {
  const linkProps =
    btnType === 'cancel'
      ? {
          pathname: to,
          state: {
            ignorePrevent: true,
          },
        }
      : to;

  return <SharedButton as={Link} to={linkProps} btnType={btnType} {...buttonProps} />;
};
