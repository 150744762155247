import { AuthContextProvider } from '@modules/auth/context';
import ToastsProvider from '@shared/components/toasts/ToastsProvider';
import { GlobalStyles } from '@styles/global';
import theme from '@styles/theme';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import RootRoutes from './routes';
import history from '@root/app-history';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastsProvider />
      <HelmetProvider>
        <Router history={history}>
          <AuthContextProvider>
            <RootRoutes />
          </AuthContextProvider>
        </Router>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
