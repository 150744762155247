import { Formik, FormikConfig, FormikHelpers, FormikProps, FormikValues } from 'formik';
import * as EI from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as H from 'history';
import React, { PureComponent } from 'react';
import { Prompt } from 'react-router-dom';
import history from '../../../../app-history';
import FormikEffects from './FormikEffects';
import FormikPreventLeaveModal from './FormikPreventLeaveModal';
import FormikScrollingError from './FormikScrollingError';

export type FormikPreventLeaveResult = Promise<EI.Either<unknown, O.Option<string>>>;

export interface EnhanceFormikProps<Values> extends Omit<FormikConfig<Values>, 'onSubmit'> {
  preventLeave?: boolean;
  disableErrorScrolling?: boolean;
  onChanged?: (values: Values) => void;
  onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => FormikPreventLeaveResult;
}

interface EnhanceFormikState {
  formChanged: boolean;
  confirmedLeave: boolean;
  modalVisible: boolean;
  lastLocation?: H.Location<any> | null;
  submitting: boolean;
}

class EnhanceFormik<Values = FormikValues> extends PureComponent<EnhanceFormikProps<Values>, EnhanceFormikState> {
  state: EnhanceFormikState = {
    formChanged: false,
    confirmedLeave: false,
    modalVisible: false,
    submitting: false,
  };

  public form: FormikProps<Values> | null = null;

  private showModal = (location: H.Location<any>) => {
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });
  };

  private closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  private handlePreventLeave = (location: H.Location<any>): boolean => {
    const { confirmedLeave } = this.state;

    const ignorePrevent = location.state != null && location.state.ignorePrevent;

    if (!confirmedLeave && !ignorePrevent) {
      this.showModal(location);
      return false;
    }

    return true;
  };

  private redirectToLastLocation = () => {
    if (this.state.modalVisible) {
      pipe(
        O.fromNullable(this.state.lastLocation),
        O.fold(
          () => history.push('/articles'),
          location => history.push(location),
        ),
      );
    }
  };

  private onLeave = () => {
    this.setState(
      {
        confirmedLeave: true,
      },
      () => this.redirectToLastLocation(),
    );
  };

  private onSubmitAndLeave = () => {
    if (this.form !== null) {
      if (this.form.isValid) {
        this.setState({ formChanged: false });
      } else {
        this.setState({ modalVisible: false });
      }

      this.form.submitForm();
    }
  };

  private handleChange = (values: Values) => {
    if (this.props.onChanged) {
      this.props.onChanged(values);
    }
    this.setState({ formChanged: true });
  };

  private handleSubmit = (values: Values, formikHelpers: FormikHelpers<Values>) => {
    this.setState({
      submitting: true,
    });

    return this.props.onSubmit(values, formikHelpers).then(res => {
      pipe(
        res,
        EI.fold(
          () => {
            this.setState({
              submitting: false,
              modalVisible: false,
            });
          },
          result => {
            this.setState({
              formChanged: false,
              submitting: false,
            });

            return pipe(
              result,
              O.fold(
                () => this.redirectToLastLocation(),
                url => {
                  if (this.state.modalVisible) {
                    this.redirectToLastLocation();
                  } else {
                    history.push(url, { ignorePrevent: true });
                  }
                },
              ),
            );
          },
        ),
      );
    });
  };

  render() {
    const { children, preventLeave, disableErrorScrolling, onSubmit, onChanged, ...formikProps } = this.props;

    const { modalVisible, submitting } = this.state;

    return (
      <Formik ref={this.form} onSubmit={this.handleSubmit} {...formikProps}>
        {formikProps => {
          this.form = formikProps;

          return (
            <>
              {!disableErrorScrolling && <FormikScrollingError />}

              {(preventLeave || onChanged) && <FormikEffects onChange={this.handleChange} />}

              {preventLeave && (
                <>
                  <Prompt message={this.handlePreventLeave} when={this.state.formChanged} />
                  <FormikPreventLeaveModal
                    open={modalVisible}
                    submitting={submitting}
                    onClose={this.closeModal}
                    onLeave={this.onLeave}
                    onSubmitAndLeave={this.onSubmitAndLeave}
                  />
                </>
              )}
              {children}
            </>
          );
        }}
      </Formik>
    );
  }
}

export default EnhanceFormik;
