import React from 'react';

import { createRoot } from 'react-dom/client';
import { configureSentry } from '@shared/modules/sentry/utils';
import '@styles/css';

import App from './App';

configureSentry();

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(<App />);
}
