import { HttpError, HttpStatusCode } from '@core/http';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import * as Styled from './ErrorPage.styles';

interface ErrorPageProps {
  error?: HttpError;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = HttpError.notFound }) => {
  return (
    <Styled.ErrorPageContainer>
      <div>
        <h1>
          {HttpStatusCode.NOT_FOUND === error.status
            ? 'La page que vous cherchez est introuvable'
            : `Une erreur ${error.status} est survenue`}
        </h1>
      </div>

      <p>Nous vous invitons à revenir à l'accueil</p>

      <Button as={Link} primary to="/articles">
        Revenir à l'accueil
      </Button>
    </Styled.ErrorPageContainer>
  );
};

export default ErrorPage;
