import React, { FC, useEffect } from 'react';
import { useSendTask } from '@core/http/hooks';
import { usePageProps } from '@layout/page/hooks';
import Page from '@layout/page/Page';
import { useAuthContext } from '@modules/auth/context';
import { EnhanceFormik, mapToPreventLeaveResult, useExternalSubmitButton } from '@shared/modules/form';
import { renderOptional } from '@shared/utils/render';
import { SharedButton } from '@styles/shared';
import * as ProfileService from '@modules/auth/service';
import * as O from 'fp-ts/Option';
import ProfileForm from '@shared/modules/profile/form/ProfileForm';
import { mapProfileToProfileParam } from '@shared/modules/profile/utils';
import { ProfileParams } from '@modules/auth/model';
import { updateProfileSchema } from '@shared/modules/profile/schema';

const ProfileUpdatePage: FC = () => {
  const { profile, requestUpdateProfile } = useAuthContext();

  const [loading, update] = useSendTask(ProfileService.updateProfile, {
    successMessage: 'Vote profil a bien été modifié',
  });

  const { formRef, handleSubmit, handleFormChanged } = useExternalSubmitButton();

  const handleUpdateUser = (user: ProfileParams) => {
    return update(user).then(res => {
      requestUpdateProfile();

      return mapToPreventLeaveResult(res);
    });
  };

  const { pageProps, updatePageProps } = usePageProps({
    breadcrumbs: [{ title: 'Profile', to: '/profile' }],
    bottom: {
      right: [<SharedButton key="save" btnType="save" onClick={handleSubmit} loading={loading} />],
    },
  });

  useEffect(() => {
    if (O.isSome(profile)) {
      updatePageProps({
        breadcrumbs: [
          { title: 'Profile', to: '/profile' },
          { title: `${profile.value.firstName} ${profile.value.lastName}` },
        ],
        bottom: {
          right: [
            renderOptional(profile, profile => (
              <SharedButton key="save" btnType="save" onClick={handleSubmit} loading={loading} />
            )),
          ],
        },
      });
    }
  }, [updatePageProps, profile, handleSubmit, loading]);

  return (
    <Page {...pageProps}>
      {renderOptional(profile, profile => (
        <EnhanceFormik
          ref={formRef}
          initialValues={mapProfileToProfileParam(profile)}
          validationSchema={updateProfileSchema}
          onSubmit={handleUpdateUser}
          onChanged={handleFormChanged}
          preventLeave>
          <ProfileForm profile={profile} />
        </EnhanceFormik>
      ))}
    </Page>
  );
};

export default ProfileUpdatePage;
